import {Directive, ElementRef, HostListener} from '@angular/core';
import {MouseService} from '@shared/services/mouse.service';

@Directive({
  selector: '[mouse]'
})
export class MouseDirective {
  constructor(
    private el: ElementRef,
    private mouseService: MouseService,
    ) {
  }

  // @HostListener('mousedown', ['$event'])
  // onMouseDown(event: KeyboardEvent) {
  //   //console.log('mousedown')
  // }
  //
  // @HostListener('mousemove', ['$event'])
  // onMouseMove(event: KeyboardEvent) {
  //   //console.log('mousemove')
  // }

  //@HostListener('mouseup')
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: KeyboardEvent) {
    this.mouseService.mouseUp = event;
  }

}
