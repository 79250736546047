import {Action} from '@ngrx/store';
import {User} from '@shared/models/user.model';

export enum AuthActionsTypes {
  RegistryUser = '[RegistryUser] user',
  RegistryUserSuccess = '[RegistryUserSuccess] user',
  RegistryUserError = '[RegistryUserError] user',
  SignInUser = '[SignInUser] user',
  SignInUserSuccess = '[SignInUserSuccess] user',
  SignInUserError = '[SignInUserError] user',
  LogOut = '[LogOut] LogOut user',
  DefaultUser = '[DefaultUser] DefaultUser user'
}

export class DefaultUser implements Action {
  readonly type = AuthActionsTypes.DefaultUser;

  constructor(public payload?: any) {
  }
}
export class RegistryUser implements Action {
  readonly type = AuthActionsTypes.RegistryUser;

  constructor(public payload: any) {
  }
}

export class RegistryUserSuccess implements Action {
  readonly type = AuthActionsTypes.RegistryUserSuccess;

  constructor(public payload: any) {
  }
}

export class RegistryUserError implements Action {
  readonly type = AuthActionsTypes.RegistryUserError;

  constructor(public payload: any) {
  }
}

export class AuthenticationSignInUser implements Action {
  readonly type = AuthActionsTypes.SignInUser;

  constructor(public payload: User) {
  }
}

export class AuthenticationSignInUserSuccess implements Action {
  readonly type = AuthActionsTypes.SignInUserSuccess;

  constructor(public payload: any) {
  }
}

export class AuthenticationSignInUserError implements Action {
  readonly type = AuthActionsTypes.SignInUserError;

  constructor(public payload: any) {
  }
}

export class LogOut implements Action {
  readonly type = AuthActionsTypes.LogOut;

  constructor() {
  }
}

export type AuthenticationActions =
  RegistryUser
  | RegistryUserSuccess
  | RegistryUserError
  | AuthenticationSignInUser
  | AuthenticationSignInUserSuccess
  | AuthenticationSignInUserError
  | LogOut;
