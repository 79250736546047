import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {reducers} from './state.reducers';
import {EffectsModule} from '@ngrx/effects';
import {NavigationMenuEffect} from './navigation-menu/navigation-menu.effect';
import {effectsStore} from '@root/state/state.effects';
// import {NgrxActionsModule} from 'ngrx-actions/dist';

@NgModule({
  imports: [
    //NgrxActionsModule,
    EffectsModule.forRoot([]),
    EffectsModule.forFeature(effectsStore),
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    })
  ],
  exports:[
  ]
})
export class StateModule {
  constructor(
      @Optional()
      @SkipSelf()
          parentModule: StateModule
  ) {
    if (parentModule) {
      throw new Error('StateModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: StateModule
    };
  }
}
