import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NavigationMenuType } from '@shared/modules/navigation-menu/navigation-menu-type.enum';
import { NavigationMenuService } from '@shared/modules/navigation-menu/navigation-menu.service';
import { EntityName } from '@shared/models/entity-name.enum';
import { EntityService } from '@shared/services/entity.service';
import { SessionService } from '@shared/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateDataService {
  private _lastRecord$: ReplaySubject<any> = new ReplaySubject<any>(null);
  private _list$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _lastRecordId$: ReplaySubject<number> = new ReplaySubject<number>();
  private entityId: number;
  private _attributes$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public navigationMenuService: NavigationMenuService,
    public sessionService: SessionService,
    public entityService: EntityService,
  ) {
  }

  create(entityId: number, data: any = {}): Observable<any> {
    return this.entityService.setData(EntityName.BaseModule, entityId, data).pipe(
      map((id: number) => {
        this.getLastRecordById(entityId, id);
        return id;
      }));
  }

  getLastRecord() {
    // this.navigationMenuService.getMenu()
    //   .pipe(map(epics => (epics || []).filter(epic => epic.name === NavigationMenuType.BaseModule && epic.use_questionnaire == true)[0]))
    //   .subscribe((item) => {
    //     this.entityId = Number(item.id);
    //     this.getList(this.entityId);

    //     this.lastRecordId$.subscribe((id) => {
    //       //console.log('lastRecordId', id);
    //       if (id) {
    //         forkJoin([
    //           this.entityService.getAttributes(EntityName.BaseModule, this.entityId),
    //           this.entityService.getEntityByIdAndId(EntityName.BaseModule, this.entityId, id),
    //         ]).subscribe(
    //           (item) => {
    //             const attributes = {};
    //             const data = item[1];
    //             Object.keys(data).forEach(key => {
    //               attributes[key] = item[0][key];
    //               attributes[key].value = data[key];
    //               if (attributes[key].type == 'select') {
    //                 (attributes[key].options || []).filter((_item: any) => {
    //                   if (_item.label == attributes[key].value) {
    //                     _item.selected = true;
    //                     attributes[key].selectDescription = _item.description;
    //                     attributes[key].selectHint = _item.hint;
    //                     attributes[key].selectWeightValue = Number(_item.weight_value || 0);
    //                   }
    //                 });
    //               }
    //             });
    //             this._lastRecord$.next(attributes);
    //           },
    //           () => {
    //           },
    //           () => {
    //           });
    //       }
    //     });
    //   });
  }

  getLastRecordById(entityId: number, id: number) {
    // if(!entityId){
    //   debugger
    // }
    forkJoin([
      this.entityService.getAttributes(EntityName.BaseModule, this.entityId),
      this.entityService.getEntityByIdAndId(EntityName.BaseModule, this.entityId, id),
    ]).subscribe(
      (item) => {
        let object = {};
        Object.keys(item[1]).forEach(key => {
          object[key] = item[0][key];
          object[key].value = item[1][key];
        });
        this._lastRecord$.next(object);
      },
      () => {
      },
      () => {
      });
  }

  getList(id: number) {
    const user = this.sessionService.session.user;
    this.entityService.getList(EntityName.BaseModule, id)
      .subscribe((item) => {
        (item || []).forEach((item) => {
          if (user.id == Number(item['portal_user'])) {
            item['portal_user'] = user.fio;
          }
        })

        this._list$.next(item);

        if (!item.length) {
          return;
        }
        this._lastRecordId$.next(item[0].id);
      });
  }

  get lastRecord$() {
    return this._lastRecord$.asObservable();
  }

  get lastRecordAsSubject() {
    return this._lastRecord$;
  }

  get lastRecordId$() {
    return this._lastRecordId$.asObservable();
  }

  get list$() {
    return this._list$.asObservable();
  }

  set lastRecord(value: any) {
    this._lastRecord$.next(value);
  }

  getAttributes(entityId: number): Observable<any> {
    this.entityId = entityId;
    return this.entityService.getAttributes(EntityName.BaseModule, this.entityId).pipe(
      map(attr => {
        this._attributes$.next(attr);
        return attr;
      }
      ));
  }

  get attributes$() {
    return this._attributes$.asObservable();
  }

}
