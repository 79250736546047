import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, PercentPipe} from '@angular/common';
import {DynamicPipe} from './pipes/dynamic.pipe';
import {DatePipe} from './pipes/date.pipe';
import {DateTimePipe} from '@shared/modules/pipes/pipes/date-time.pipe';

@NgModule({
  declarations: [
    DynamicPipe,
    DatePipe,
    DateTimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DynamicPipe,
    PercentPipe,
    DatePipe,
    DateTimePipe
  ],
  providers: [
    DynamicPipe,
    PercentPipe,
    DatePipe,
    DateTimePipe,
    CurrencyPipe,
  ]
})
export class PipesModule {
}
