<div *ngIf="isSteps" class="ui-steps ui-widget ui-helper-clearfix">
    <ul *ngIf="stepsService.steps">
        <li class="ui-steps-item ui-state-default ng-star-inserted"
            [ngClass]="item.styleClass"
            [class.ui-state-highlight]="stepsService.activeIndex.toString() === item.tabindex"
            *ngFor="let item of stepsService.steps; let index = index;">
            <a class="ui-menuitem-link" tabindex="{{index}}" (click)="onClick(item, index)">
                <span class="ui-steps-number" [innerText]="index+1"></span>
                <span class="ui-steps-title" [innerHTML]="item.label"></span>
            </a>
        </li>
    </ul>
</div>