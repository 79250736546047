import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TabletNavigationItemClickDirective} from '@shared/modules/navigation-menu/tablet-navigation-item-click.directive';



@NgModule({
  declarations: [
    TabletNavigationItemClickDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabletNavigationItemClickDirective
  ]
})
export class NavigationMenuModule { }
