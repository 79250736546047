import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Configuration} from '../models/config';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EntityName} from '@shared/models/entity-name.enum';
import {iResponse} from '@shared/models/response.interface';

@Injectable()
export class SettingsService {
  private config = './assets/config.json';

  promise: Promise<any>;
  private status = false;

  constructor(private http: HttpClient) {
  }

  getEntityListByUseQuestionnaire(): Observable<any> {
    return this.http.get(`system/getEntityListByUseQuestionnaire`).pipe(
        map((item:iResponse) => item.data)
      );
  }

  load() {
    // @ts-ignore
    this.promise = this.http.get(this.config, {
      params: null
    })
      .pipe(
        map((data: any) => {
          this.reWriteConfig(data);
        }))
      .toPromise();
  }

  isStatusLoad() {
    return this.status;
  }

  reWriteConfig(data: any) {
    Configuration.reWrite(data);
    this.status = true;
  }
}
