import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs/index';
import {StorageService} from '@shared/modules/storage/services';
import {Configuration} from '@shared/modules/outer-config/models/config';
import {SessionService} from '@shared/services/session.service';

@Injectable({
  providedIn: "root"
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    public storage: StorageService,
    public sessionService: SessionService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('i18n')) {
      return next.handle(request);
    }

    const options: { url?: string, setHeaders?: any } = {};

    options.url = `${Configuration.host+request.url}`;

    const token = this.storage.getToken();
    const headers: {
      'Content-Type'?: string,
      'Authorization'?: string
    } = {
      'Content-Type': 'application/json'
    };
    if (token && options.url.indexOf('/api/login') == -1) {
      headers[Configuration.authHeader] = token;

      options.setHeaders = headers;
      request = request.clone(options);
    }

    request = request.clone(options);
    return next.handle(request);
  }
}
