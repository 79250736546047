import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormControlsComponent} from './form-controls/form-controls.component';
import {FormControlsInputComponent} from './form-controls-input/form-controls-input.component';
import {FormControlsSelectComponent} from './form-controls-select/form-controls-select.component';
// import { FormControlsCheckboxComponent } from '@shared/modules/form-controls/form-controls-checkbox/form-controls-checkbox.component';
// import { FormControlsDatepickerComponent } from '@shared/modules/form-controls/form-controls-datepicker/form-controls-datepicker.component';
// import { FormControlsLabelComponent } from '@shared/modules/form-controls/form-controls-label/form-controls-label.component';
import {FormControlsTextareaComponent} from './form-controls-textarea/form-controls-textarea.component';
import {FormControlsMultiselectComponent} from './form-controls-multiselect/form-controls-multiselect.component';
import {FormControlsCheckComponent} from './form-controls-check/form-controls-check.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {TranslateModule} from '@ngx-translate/core';
import {CheckboxModule} from 'primeng/checkbox';
import {FormControlsAutocompleteComponent} from './form-controls-autocomplete/form-controls-autocomplete.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormControlsDatepickerComponent} from '@shared/modules/form-controls/form-controls-datepicker/form-controls-datepicker.component';
import {FormControlsCheckboxComponent} from '@shared/modules/form-controls/form-controls-checkbox/form-controls-checkbox.component';
import {FormControlsLabelComponent} from '@shared/modules/form-controls/form-controls-label/form-controls-label.component';

// import {CustomValidationService} from '@shared/modules/form-controls/custom-validation.service';

@NgModule({
  declarations: [
    FormControlsInputComponent,
    FormControlsSelectComponent,
    FormControlsCheckboxComponent,
    FormControlsDatepickerComponent,
    FormControlsLabelComponent,
    FormControlsComponent,
    FormControlsTextareaComponent,
    FormControlsMultiselectComponent,
    FormControlsCheckComponent,
    FormControlsAutocompleteComponent,
    //FormControlsPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    TranslateModule,
    CheckboxModule,
    AutoCompleteModule
  ],
  providers: [
    //CustomValidationService
  ],
  exports: [
    FormControlsInputComponent,
    FormControlsCheckboxComponent,
    FormControlsDatepickerComponent,
    FormControlsLabelComponent,
    FormControlsSelectComponent,
    FormControlsTextareaComponent,
    FormControlsCheckComponent,
    FormControlsMultiselectComponent,
    FormControlsAutocompleteComponent,
    FormControlsComponent
  ]
})
export class FormControlsModule {
}
