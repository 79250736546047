import {RoleEnum} from './role.enum';
import {Role} from './role.model';

export class User {
  email: string;
  fio: string;
  id: number;
  password: string;
  private _role: Role;

  constructor(data?) {
    if (data) {
      this.id = data.id;
      this.fio = data.fio;
      this.email = data.email;
      this.password = data.password;
      //this._role = new Role(data.roleId);
    }
  }

  get value() {
    return {
      id: this.id,
      email: this.email,
      fio: this.fio
    };
  }

  get role(): Role {
    return this._role;
  }

  setRole(roleId: RoleEnum) {
    //this.roleId = roleId;
    //this._role.setRoleName(roleId);

    return this;
  }
}
