import {NavigationMenuItem} from '@shared/modules/navigation-menu/navigation-menu-item.interface';
import * as NavigationMenuActions from './navigation-menu.actions';
import {Action, createReducer, on} from '@ngrx/store';

export interface NavigationMenuState {
  navigationMenu: NavigationMenuItem[]
}

export const initialMenuState: NavigationMenuState = {
  navigationMenu: []
};

const navigationReducer = createReducer(
  initialMenuState,
  on(NavigationMenuActions.loadNavigationMenu, state => ({ ...state, navigationMenu: state.navigationMenu })),
  on(NavigationMenuActions.loadSuccessNavigationMenu, state => ({ ...state, navigationMenu: state.navigationMenu })),
  on(NavigationMenuActions.updateNavigationMenu, state => state),
  // on(ScoreboardPageActions.setScores, (state, { game }) => ({ home: game.home, away: game.away }))
)

export function navigationMenuReducer(state: NavigationMenuState = initialMenuState, action: Action): NavigationMenuState {
 return navigationReducer(state, action);
}
