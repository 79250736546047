import {NavigationMenuItem} from '@shared/modules/navigation-menu/navigation-menu-item.interface';

export const NavigationType = {
  User: (item: NavigationMenuItem) => {
    item.route = `/user/personal`;
    item.queryParams = {
      moduleId: item.id
    }
    item.icon = 'fa-user';
  },
  BaseModule: (item: NavigationMenuItem) => {
    if (item.use_questionnaire && item.title == 'Анкетные данные') {
      item.route = `/private-data/list`;
    } else {
      item.route = `/standard/list`;
    }

    item.icon = 'fa-dashboard';
    item.queryParams = {
      moduleId: item.id
    }
  },
  UserMessage: (item: NavigationMenuItem) => {
    item.route = `/communication/message-list`;
    item.queryParams = {
      moduleId: item.id
    }
    item.icon = 'fa-comment';
  },
  TechnicalSupport: (item) => {
    item.route = `/settings/contacts`;
    item.queryParams = {
      moduleId: item.id
    }
    item.icon = 'fa-wrench';
  }
};
