import {User} from '../../shared/models/user.model';

export interface iSession {
  token: string;
  user: User;
  logoType: string;
  background: string;
}

export class Session {
  token: string;
  _user: User;
  logoType: string = '';
  background: string = '';
  enable: boolean;
  language: string;
  static key: 'Session';

  constructor(data?: any) {
    if (data) {
      if (data.design) {
        this.logoType = data.design.logo_img || '';
        this.background = data.design.background_anonimus_img || '';
      }
      this.language = data.language;
      this.enable = data.enable;
      this.token = data.token;
      this.setUser(new User(data));
    }
  }

  // get JSON of data
  get value() {
    return {
      ...this._user.value,
      token: this.token,
      role: this._user.role,
      design: {
        logo_img: this.logoType,
        background_anonimus_img: this.background
      }
    };
  }

  setToken(token: string) {
    this.token = token;

    return this;
  }

  getToken() {
    return this.token;
  }


  get user(): any {
    return this._user || {};
  }

  set user(value: any) {
    // debugger;
    this._user = value;
  }


  setUser(user: User) {
    this._user = user;

    return this;
  }
}
