import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  modal: any;

  @Input()
  withControls: boolean;

  @Input()
  confirmText: string;

  @Input()
  cancelText: string;

  @Output()
  onConfirm: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public confirmClick(): void {
    this.onConfirm.emit();
  }

  public confirmShown(): boolean {
    return this.confirmText !== undefined;
  }

  public cancelShown(): boolean {
    return this.cancelText !== undefined;
  }

  close() {

  }
}
