import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormControlsComponent} from '@shared/modules/form-controls/form-controls/form-controls.component';
import {Dropdown} from 'primeng';

@Component({
  selector: 'app-form-controls-select',
  templateUrl: './form-controls-select.component.html',
  styleUrls: ['./form-controls-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormControlsSelectComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FormControlsSelectComponent),
    multi: true
  }]
})
export class FormControlsSelectComponent extends FormControlsComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() translate: boolean = true;
  @Input() revertPositionDropdown: boolean = false;
  @Input() disabled: boolean = false;
  @ViewChild(Dropdown) dropdown: Dropdown;

  updateSelectValue(event) {
    this.value = event.value;
    this.onChange(event.value);
    this.onChangeEmit(event.value);
    this.onTouched();
  }


  onDropdownShow() {
    this.open.emit(true);
    if (this.revertPositionDropdown) {
      setTimeout(() => {
        this.revertDropDown();
      }, 1);
    }
  }

  private revertDropDown() {
    let p: HTMLElement = this.dropdown.el.nativeElement.querySelector('.ui-dropdown-panel');

    if (p) {
      let itemsPanelHeight = p.offsetHeight;

      // Add a little space
      itemsPanelHeight += 3;
      p.style.top = `-${itemsPanelHeight}px`;
    }
  }
}
