import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Notification} from './notification.model';
import {HttpClient} from '@angular/common/http';
import {NotificationType} from './notification-type.enum';
import {map} from 'rxjs/operators';
import {Configuration} from '../outer-config/models/config';

@Injectable()
export class NotificationService {
  private _subject = new BehaviorSubject<Notification>(null);
  private _idx = 0;

  private timeOut = Configuration.notificationTimeOut;

  constructor(
      private http: HttpClient
  ) {
  }

  getCount(userId: number): Observable<number> {
    return this.http.get<number>(`Notification/Count?userId=${userId}`);
  }

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  info(message: string, title?: string, timeout?: number ) {
    timeout = timeout || this.timeOut;
    this._subject.next(new Notification(this._idx++, NotificationType.info, title || 'Info', message, timeout));
  }

  success(message: string, title?: string,  timeout?) {
    timeout = timeout || this.timeOut;
    this._subject.next(new Notification(this._idx++, NotificationType.success, title || 'Успех', message, timeout));
  }

  warning(message: string, title?: string, timeout?: number) {
    timeout = timeout || this.timeOut;
    this._subject.next(new Notification(this._idx++, NotificationType.warning, title || 'Warning', message, timeout));
  }

  error(message: string, title?: string, timeout?: number ) {
    timeout = timeout || this.timeOut;
    this._subject.next(new Notification(this._idx++, NotificationType.error, title || 'Ошибка', message, timeout));
  }

}
