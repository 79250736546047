import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonFormComponent} from '@shared/components/common-form/common-form.component';
import {PrivateData} from '@root/user/private-data/private-data.model';
import {iPrivateData} from '@root/user/private-data/private-data.interface';
import {FormBuilder} from '@angular/forms';
import {CommonFormService} from '@shared/components/common-form/common-form.service';

@Component({
  selector: 'bitKogan-private-data',
  templateUrl: './private-data.component.html',
  styleUrls: ['./private-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateDataComponent  extends CommonFormComponent implements OnInit {
  @Input()
  model: PrivateData;

  @Input()
  revertPositionDropdown: boolean = false;

  constructor(
    fb: FormBuilder,
    formService: CommonFormService
  ) {
    super(fb, formService);
  }

  ngOnInit(): void {
    if (!this.form) { console.log('form', this.model.listToFormGroup);
      this.buildForm(this.model.listToFormGroup);
    } else {
      this.form.patchValue(this.model.value);
    }

    this.form.valueChanges.subscribe((item) => {
      Object.keys(item).forEach((key) => {
        this.model.data[key].value = item[key];
      });
    });
  }

  getTitle(field: string) {
    return this.model.data[field].title;
  }

  onChangeSelect(item, id: string) {
    (item.options || [])
      .filter(item => {
        if (item.id == id) {
          item.selected = true;
        }
      });
  }

  get isLawyer(): boolean {
    return this.form.get('field8').value == 1
  }

  get isIndividual(): boolean {
    return this.form.get('field8').value == 2
  }

  get conservative(): boolean{
    return this.form.get('field14').value == 1
  }
  get balanced(): boolean{
    return this.form.get('field14').value == 2
  }
  get aggressive(): boolean{
    return this.form.get('field14').value == 3
  }

  get lawyerFields() {
    return [
      'field4',
      'field5',
      'field6'
    ]
  }

  get individualFields() {
    return [
      'field9',
      'field10',
      'field11',
      'field2',
      'field3',
    ]
  }

  validation(): boolean {
    if (!this.form) {
      return false;
    }

    this.form.markAllAsTouched();

    return this.form.valid;
  }

}
