import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityName } from '@shared/models/entity-name.enum';
import { map } from 'rxjs/operators';
import { iResponse } from '@shared/models/response.interface';
import {Sort} from '@shared/models/sort.model';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAttributesByUseQuestionnaire(entityName: EntityName, entityId: number): Observable<any> {
    return this.http.get(`entity/getAttributesByUseQuestionnaire/${entityName}/${entityId}`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getAttributes(entityName: EntityName, id: number): Observable<any> {
    return this.http.get(`entity/getAttributes/${entityName}/${id}`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  setData(entityName: EntityName, id: number, data: any = {}): Observable<any> {
    return this.http.post(`entity/setData/${entityName}/${id}`, data).pipe(
      map((item: iResponse) => item.data)
    );
  }

  updateData(entityName: EntityName, id: number, data: any = {}): Observable<any> {
    return this.http.put(`entity/setData/${entityName}/${id}`, data).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getList(entityName: EntityName, id: number): Observable<any> {
    return this.http.get(`entity/getData/${entityName}/${id}`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getListFiltered(entityName: EntityName, id: number, sort?: Sort): Observable<any> {
    let params = new HttpParams();

    if (sort) {
      params = params.append('order', sort.field);
    }

    return this.http.get(`entity/getData/${entityName}/${id}`, {
      params: params
    }).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getDataById(entityName: EntityName, id: number): Observable<any> {
    return this.http.get(`entity/getDataById/${entityName}/${id}`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  setDataByEntityId(entityName: EntityName, entityId: number, id: number, data: any): Observable<any> {
    return this.http.put(`entity/setDataByEntityId/${entityName}/${entityId}/${id}`, data).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getEntityByIdAndId(entityName: EntityName, entityId: number, id: number): Observable<any> {
    return this.http.get(`entity/getDataByEntityId/${entityName}/${entityId}/${id}`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getTechnicalSupport(): Observable<any> {
    return this.http.get(`entity/getDataOne/TechnicalSupport`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getUserMessage(): Observable<any> {
    return this.http.get(`entity/getData/UserMessage`).pipe(
      map((item: iResponse) => item.data)
    );
  }

  getUserMessageAttributes(): Observable<any> {
    return this.http.get(`entity/getAttributes/UserMessage`).pipe(
      map((item: iResponse) => item.data)
    );
  }

}
