<div class="private-data-content">
  <form novalidate
        *ngIf="form"
        [class.submitted]="formService.submitted"
        [formGroup]="form">

    <div *ngFor="let item of modelData">
      <div class="form-group"
           *ngIf="item.type_db == 'string' && item.display">
          <app-form-controls-input
            [label]="item.title"
            placeholder="Введите значение"
            [formControlName]="item.name"
            [hideErrors]="true"
            [errors]="form.get(item.name).errors"
            [disabled]="disabled">
          </app-form-controls-input>
      </div>

      <div class="form-group"
           *ngIf="item.type_db == 'datestamp'">
          <app-form-controls-datepicker
            [label]="item.title"
            [formControlName]="item.name"
            [placeholder]="'Введите дату'"
            [errors]="form.get(item.name).errors">
          </app-form-controls-datepicker>
      </div>

      <div class="form-group"
           *ngIf="item.type == 'select'">
        <div #headerSelect></div>
        <app-form-controls-select
          [className]="'form-control-'+item.name"
          [label]="item.title"
          #selectControl
          [options]="item.options"
          placeholder="Выберите значение"
          [formControlName]="item.name"
          [hideErrors]="true"
          [hint]="item.selectHint"
          [revertPositionDropdown]="revertPositionDropdown"
          (change)="onChangeSelect(item, $event)"
          (open)="onOpenSelect($event, item.options.length, selectControl)"
          [errors]="form.get(item.name).errors"
          [disabled]="disabled">
        </app-form-controls-select>

        <div *ngIf="item.selectDescription as description"
             class="description"
             [innerHTML]="description"></div>

      </div>

    </div>
  </form>
</div>
