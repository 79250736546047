<div class="file-uploader-wrapper">
  <input (click)="$event.stopPropagation(); fileInput.click()" [value]="file ? file.name : ''" type="text" class="name-input form-control" [placeholder]="placeholder" readonly>

  <input #fileInput
         (change)="openFile($event)"
         type="file"
         class="file-input"
         [accept]="formats"
         [multiple]="multiple">

  <button class="btn btn-primary" (click)="submit()">Обновить данные</button>
</div>
<div class="error" *ngIf="file && !isValid()">Wrong file size</div>
