import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() multiple: boolean = false;
  @Input() size: number;
  @Input() formats: string | string[];
  @Input() placeholder: string = 'Загрузите файл';

  @Output() onSubmit: EventEmitter<File> = new EventEmitter<File>();

  file: File;

  constructor() { }

  ngOnInit() {
    this.formats = this.formats.toString();
  }

  openFile(e): void {
    this.file = e.target.files[0];
  }

  isValid(): boolean {
    if (!this.size) {
      return true;
    }

    return this.file.size <= this.size;
  }

  submit(): void {
    if (!this.isValid()) {
      return;
    }

    this.onSubmit.emit(this.file);
  }
}
