import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {State} from '@root/state/state.interface';
import {Router} from '@angular/router';
import {authenticationIdentity} from '@root/state/authentication';
import {Configuration} from '@shared/modules/outer-config/models/config';
import {CommonTranslateService} from '@shared/modules/common-translate/common-translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'TeamCraft Personal Office';

  constructor(
    public commonTranslateService: CommonTranslateService,
    private router: Router,
    private store: Store<State>
  ) {

  }

  ngOnInit(): void {

    // this.store.pipe(
    //   select(authenticationIdentity)
    // )
    //   .subscribe((item) => {
    //     debugger
    //     this.router.navigate(['/auth']);
    //   });
  }
}
