import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {CommonTranslateService} from '@shared/modules/common-translate/common-translate.service';
// // AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    {prefix: './assets/i18n/', suffix: '.json'},
    // { prefix: `${window.location.origin}/config/${JSON.parse(localStorage.getItem('brand')).brand.brandKey}/`, suffix: '.json'}
  ]);
  // return new TranslateHttpLoader(httpClient, `${window.location.origin}/config/${JSON.parse(localStorage.getItem('brand')).brand.brandKey}/`, '.json');
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
  ],
  providers: [
    CommonTranslateService
  ],
  exports: [
    TranslateModule
  ]
})
export class CommonTranslateModule {
}
