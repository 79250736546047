import {User} from '@shared/models/user.model';
import {AuthActionsTypes, AuthenticationActions} from '@root/state/authentication/authentication.action';

export interface AuthenticationState{
  user: User,
  signUp: any;
  signIn: any;
  token: string;
  identity: boolean; // status authorization of user
}

export const initialAuthenticationState: AuthenticationState = {
  user: null,
  signUp: null,
  signIn: null,
  identity: false,
  token: null
}

export function authenticationReducer(state: AuthenticationState = initialAuthenticationState, action: AuthenticationActions): AuthenticationState {
  switch (action.type) {
    case AuthActionsTypes.SignInUser: {
      return {
        ...state,
        signIn: action.payload
      }
    }
    case AuthActionsTypes.RegistryUser:{
      return {
        ...state
      };
    }
    case AuthActionsTypes.SignInUserSuccess:{
      return {
        ...state,
        token: action.payload
      };
    }
    case AuthActionsTypes.SignInUserError:{
      return {
        ...state,
        token: action.payload
      };
    }
    default:{
      return state;
    }
  }
}

export const getAuthenticationSignIn = (state: AuthenticationState) => { return state.signIn;};
export const getAuthIdentity = (state: AuthenticationState) => state.identity;
