import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SessionService} from '@shared/services/session.service';

@Injectable()
export class SignUpGuard implements CanActivate {

  constructor(
    public sessionService: SessionService,
    public router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (this.sessionService.checkToken()) {
      console.log('SignUpGuard canActivate', true);
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}

