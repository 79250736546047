import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-switch-view',
  templateUrl: './switch-view.component.html',
  styleUrls: ['./switch-view.component.scss']
})
export class SwitchViewComponent implements OnInit {

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onList() {
    const queryParams: Params = { type: 'list' };

    this.addParamsToUrl(queryParams);
  }

  onCalendar() {
    const queryParams: Params = { type: 'calendar' };

    this.addParamsToUrl(queryParams);
  }

  private addParamsToUrl(queryParams: Params) {
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
