import {ActionReducerMap} from '@ngrx/store';

import {State} from './state.interface';
import {navigationMenuReducer} from './navigation-menu/navigation-menu.reducer';
import {authenticationReducer} from '@root/state/authentication/authentication.reducer';
import {dataReducer} from '@root/state/data/data.reducer';
import {notificationReducer} from '@root/state/notification/notification.reducer';

export const reducers: ActionReducerMap<State> = {
    notification: notificationReducer,
    navigationMenu: navigationMenuReducer,
    authentication: authenticationReducer,
    data: dataReducer
}
