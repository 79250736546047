import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MouseService {
  private _mouseUp: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    console.log('MouseService');
  }

  get mouseUp$(): Observable<any> {
    return this._mouseUp.asObservable();
  }

  set mouseUp(value: any) {
    this._mouseUp.next(value);
  }
}
