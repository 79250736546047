import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/services/auth.service';
import {SessionService} from '@shared/services/session.service';

@Injectable()
export class AuthGuard implements CanActivate{

  constructor(
    public sessionService: SessionService,
    public router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
    this.sessionService.updateSessionFromStore();

    if (!this.sessionService.checkToken()) {
      console.log('AuthGuard exit');
      this.router.navigate(['auth']);
      return false;
    }
    return true;
  }
}

