import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Paginator } from './paginator.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent{
  pageItems: number[] = [0];
  _totalItems = 0;
  _pageSize = 10;
  _currentPage = 0;
  _buttonsMaxRange = 3;

  @Input()
  set data(pager: any){
    this.pageItems = pager.pageItems;
  }

  @Input()
  set currentPage(currentPage: number) {
    this._currentPage = currentPage;
    this.updatePages();
  }

  get currentPage() { return this._currentPage; }

  @Input()
  set buttonsMaxRange(buttonsMaxRange: number) {
    this._buttonsMaxRange = buttonsMaxRange;
    this.updatePages();
  }

  get buttonsMaxRange() { return this._buttonsMaxRange; }

  @Output()
  currentPageChange = new EventEmitter<number>();

  onChangeObj(page: number) {
    this.currentPage = page;
    this.currentPageChange.emit(page);
  }

  @Input()
  set pageSize(pageSize: number) {
    this._pageSize = pageSize;
    this.updatePages();
  }

  get pageSize() {
    return this._pageSize;
  }

  get toSize() {
    return this.pageSize < this._totalItems ? this.pageSize : this._totalItems;
  }

  @Input()
  set totalItems(totalItems: number) {
    this._totalItems = totalItems;
    this.updatePages();
  }

  get totalItems() { return this._totalItems; }

  private updatePages() {
    const totalPages = Math.ceil(this._totalItems / this._pageSize);

    const temp: number[] = [];
    this.pageItems = [];

    temp[0] = 0;
    temp[totalPages - 1] = totalPages - 1;

    for (let i = this._currentPage - this._buttonsMaxRange; i <= this._currentPage + this._buttonsMaxRange; i++) {
      temp[i] = i;
    }

    let k = 0;
    for (let i = 0; i < temp.length; i++) {
      if ((temp[i] >= 0 && temp[i] < totalPages || temp[i] == null) && !(temp[i] == null && temp[i - 1] == null)) {
        this.pageItems[k] = temp[i];
        k++;
      }
    }
  }
}
