import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PrivateDataService} from '@root/private-data/private-data.service';
import {FormBuilder} from '@angular/forms';
import {CommonFormComponent} from '@shared/components/common-form/common-form.component';
import {iOption} from '@shared/models/option.model';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-personal-ext',
  templateUrl: './personal-ext.component.html',
  styleUrls: ['./personal-ext.component.scss']
})
export class PersonalExtComponent extends CommonFormComponent implements OnInit {
  data: iOption[] = [];

  @Output()
  loaded: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    public privateDataService: PrivateDataService,
    public fb: FormBuilder
  ) {
    super(fb);
  }

  ngOnInit(): void {
    this.privateDataService.lastRecord$
      .pipe(take(1))
      .subscribe((item: any) => {
        if (item) {
          let json = {
            field1: item.field1?.value || '',
            field7: item.field7?.value || '',
            field8: item.field8?.value || ''
          };

          this.data.push({
            label: 'field1',
            value: item.field1?.title || ''
          });
          this.data.push({
            label: 'field7',
            value: item.field7?.title || ''
          });
          this.data.push({
            label: 'field8',
            value: item.field8?.title || ''
          });

          this.buildForm(json);
        }
        this.loaded.emit(true);
      }, error => {
        this.loaded.emit(true);
      });
  }

}
