import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CommonFormComponent } from '@shared/components/common-form/common-form.component';
import { FormBuilder } from '@angular/forms';
import { iPrivateData } from '@root/user/private-data/private-data.interface';
import { CommonFormService } from '@shared/components/common-form/common-form.service';
import { PrivateData } from '@root/user/private-data/private-data.model';
import { BitKoganService } from '@root/platforms/bitkogan/bitkogan.service';
import { iOption } from '@shared/models/option.model';
import { StorageService } from '@shared/modules/storage/services';
import { REGISTRY_PRIVATE_DATE } from '@root/settings/constants/enums';
import { AuthService } from '@root/shared/services';
import { SessionService } from '@root/shared/services/session.service';
import { User } from '@root/shared/models/user.model';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-private-data',
  templateUrl: './private-data.component.html',
  styleUrls: ['./private-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateDataComponent extends CommonFormComponent implements OnInit {
  @Input()
  model: PrivateData;

  @ViewChild('headerSelect') headerSelect: ElementRef;

  @Input()
  revertPositionDropdown: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  key: string = '';

  modelData: iPrivateData[] = [];
  user: User;
  userId: number;

  constructor(
    fb: FormBuilder,
    public bitKoganService: BitKoganService,
    public storageService: StorageService,
    public authService: AuthService,
    public sessionService: SessionService,
    formService: CommonFormService
  ) {
    super(fb, formService);
  }

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm(this.model.listToFormGroup);
    } else {
      this.form.patchValue(this.model.value);
    }

    this.modelData = this.model.listToArray;

    this.authService.getData()
      .subscribe((item) => {
        this.userId = item.id;
        // console.log('this.userId', this.userId)
      });

    this.form.valueChanges
      .subscribe((item) => {
        Object.keys(item).forEach((key) => {
          this.model.data[key].value = item[key];
        });
         
        const dataFromStorage: any = this.storageService.getDataByKey(REGISTRY_PRIVATE_DATE.REGISTRY + this.userId)

        if(!dataFromStorage){
          this.model.data._key = "detail"
        }
        else{
          this.model.data._key = "sign-up"
        }

        this.storageService.setDataByKey(REGISTRY_PRIVATE_DATE.REGISTRY + this.userId, JSON.stringify(this.model.data));
      });
  }

  onOpenSelect(event: any, list: number, element: any) {
    if (element.nativeElement) {
      let p: HTMLElement = element.nativeElement.querySelector('.ui-dropdown-panel');
      if (p) {
        let itemsPanelHeight = p.offsetHeight;

        // Add a little space
        itemsPanelHeight += 3;
        p.style.top = `-${itemsPanelHeight}px`;
      }
    }
  }

  onChangeSelect(item: any, id: string) {
    (item.options || [])
      .filter((_item: iOption) => {
        if (_item.value == id) {
          _item.selected = true;
          item.selectDescription = _item.description;
          item.selectHint = _item.hint;
          item.selectWeightValue = Number(_item.weight_value || 0);
        }
      });

    // if (item.name != this.bitKoganService.roleField) {
    //   let role = this.bitKoganService.getBehaviorByPrivateData(this.model.value);
    //
    //   (this.model.data[this.bitKoganService.roleField].options || []).forEach((item) => {
    //     item.disable = (item.label != role) ? true : false;
    //   });
    // }
  }

  validation() {
    if (!this.form) {
      return false;
    }

    this.form.markAllAsTouched();

    return this.form.valid;
  }

}
