import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivateDataComponent} from './private-data/private-data.component';
import {SharedModule} from '@shared/shared.module';
import {BitKoganService} from '@root/platforms/bitkogan/bitkogan.service';
import { PersonalExtComponent } from './personal-ext/personal-ext.component';


@NgModule({
  declarations: [
    PrivateDataComponent,
    PersonalExtComponent
  ],
  providers: [
    BitKoganService
  ],
  exports: [
    PrivateDataComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class BitkoganModule {
}
