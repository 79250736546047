import {Injectable, Input} from '@angular/core';
import {MenuItem} from 'primeng';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StepsService {
    private _steps: MenuItem[];
    private _activeIndex: number = 0;
    private _activeIndex$ = new BehaviorSubject<number>(null);
    private _changeStep$ = new BehaviorSubject<number>(null);

    constructor() {
    }

    get activeIndex$(): Observable<number> {
        return this._activeIndex$.asObservable();
    }

    get changeStep$(): Observable<number> {
        return this._changeStep$.asObservable();
    }

    set changeStep(value: number) {
        this._changeStep$.next(value);
    }

    set steps(value: MenuItem[]) {
        this._steps = value;
    }

    get steps(): MenuItem[] {
        return (this._steps || []);
    }

    get activeIndex(): number {
        return this._activeIndex;
    }

    set activeIndex(value: number) {
        this._activeIndex = value;
    }

    get isPrevStep() {
        return this._activeIndex !== 0;
    }

    get isNextStep() {
        let status: boolean = false;

        if (this.steps) {
            status = (this._activeIndex + 1) < this.steps.length;
        }

        return status;
    }

    get isLastStep() {
        let status: boolean = false;

        if (this.steps) {
            status = (this._activeIndex + 1) === this.steps.length ? true : false;
        }
        return status;
    }

    next() {
        this.changeStep = this._activeIndex + 1;
    }

    prev() {
        this.changeStep = this._activeIndex - 1;
    }
}
