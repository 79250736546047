<ul *ngIf="pageItems?.length > 1" class="pagination">
    <li class="prefix green">
        Показано
        с <span [innerText]="currentPage == 0 ? 1 : (currentPage * pageSize)"></span>
        по <span [innerText]="toSize"></span>
        из <span [innerText]="totalItems"></span>
        записей
    </li>
    <li *ngFor="let page of pageItems" [ngClass]="{active:currentPage === page}" class="page-item">
        <a class="page-link" *ngIf="page != null" (click)="onChangeObj(page)">{{page + 1}}</a>
    </li>
    <li *ngIf="currentPage < pageItems.length"
        class="next">
        <a routerLink="">Далее</a>
    </li>
</ul>
