import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [],
  imports: [
    TableModule,
    PaginatorModule
  ],
  exports: [
    TableModule,
    PaginatorModule
  ]
})
export class PrimeModule {
}
