import { Injectable } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService extends Validators{

  constructor() {
    super();
  }

  static confirmPassword(group: FormGroup) {
    const value = group.value;
    if (!value) {
      return { required: true };
    } else {
      if (value.password != value.confirmPassword) {
        return { confirmation: true };
      }

      return null;
    }
  }
  static commentRequired(control: FormControl) {
    if (!control.value) {
      return { required: true };
    } else {
      return null;
    }
  }
}
