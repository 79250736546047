import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng';
import {StepsService} from '@shared/components/steps/steps.service';
import {debuglog} from 'util';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StepsComponent implements OnInit {
    @Input()
    isSteps: boolean = true;

    steps: MenuItem[];

    constructor(
        public stepsService: StepsService
    ) {
    }

    ngOnInit() {
        (this.stepsService.steps || []).forEach((item, index) => {
            item.tabindex = index.toString();
            item.styleClass = `step${index}`;
        });

        this.isSteps = (this.stepsService.steps || []).length ? true : false;
    }

    onClick(item, index) {
        this.stepsService.changeStep = index;
    }
}
