import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BitKoganService {

  constructor() {
  }

  getFieldRole() {
    return 'field14';
  }

  get roleField() {
    return 'field14';
  }

  getBehaviorByPrivateData(fields: any): string {
    let sum = 0;

    Object.keys(fields).forEach((key) => {
      let prop = fields[key];

      if (prop.type == 'select') {
        sum += (prop.selectWeightValue || 0);
      }
    });
    return this.calculatyionSum(sum);
  }

  calculatyionSum(sum: number): string {
    let value = '';

    sum = Number(sum.toFixed(2));
    console.log('SUM=', sum);
    if (0 <= sum && sum <= 0.8) {
      value = 'Консервативный';
    } else if (0.9 <= sum && sum <= 1.6) {
      value = 'Сбалансированный';
    } else if (1.6 < sum) {
      value = 'Спекулятивный';
    }
    return value;
  }

  getBehaviorByPrivateDataSum(sum: number): string {
    return this.calculatyionSum(sum);
  }
}
