<form novalidate
      [class.submitted]="formService.submitted"
      [formGroup]="form">

  <div class="row">
    <div class="col-12">
      <app-form-controls-input
        label="Fio"
        formControlName="fio"
        [hideErrors]="true"
        [errors]="form.get('fio').errors"
        [disabled]="disable">
      </app-form-controls-input>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-form-controls-input
        label="Email"
        formControlName="email"
        [disabled]="disable"
        [hideErrors]="true"
        [errors]="form.get('email').errors">
      </app-form-controls-input>
    </div>
  </div>
  <div formGroupName="pass">
    <div class="row">
      <div class="col-12">
        <app-form-controls-input
          label="Пароль"
          type="password"
          formControlName="password"
          [hideErrors]="true"
          [errors]="form.get('pass').errors"
          [disabled]="disable">
        </app-form-controls-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-form-controls-input
          label="Подтверждение пароля"
          type="password"
          formControlName="confirmPassword"
          [hideErrors]="true"
          [errors]="form.get('pass').errors"
          [disabled]="disable">
        </app-form-controls-input>

        <div class="error"
             *ngIf="form.get('pass').hasError('confirmation')"
             [innerText]="'Пароль и подтвержение пароля должны совпадать'">
        </div>
      </div>
    </div>
  </div>
</form>
