import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../shared/modules/common-notification/notification.service';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/services/auth.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  constructor(
      public notificationService: NotificationService,
      public router: Router,
      public authService: AuthService,
  ) {
  }

  ngOnInit() {
    // this.user = this.authService.getUser();
    // // this.getAll();
    // this.query(this.pagination);
  }



}
