<div class="private-data">
  <form novalidate
        *ngIf="form"
        [class.submitted]="formService.submitted"
        [formGroup]="form">

    <app-form-controls-input
      [label]="getTitle('field1')"
      placeholder="Введите значение"
      formControlName="field1"
      [hideErrors]="true"
      [errors]="form.get('field1').errors">
    </app-form-controls-input>

    <app-form-controls-input
      [label]="getTitle('field7')"
      placeholder="Введите значение"
      formControlName="field7"
      [hideErrors]="true"
      [errors]="form.get('field7').errors">
    </app-form-controls-input>

    <app-form-controls-select
      [label]="model.data['field8'].title"
      [options]="model.data['field8'].options"
      placeholder="Выберите значение"
      formControlName="field8"
      [hideErrors]="true"
      (change)="onChangeSelect('field8',$event)"
      [revertPositionDropdown]="revertPositionDropdown"
      [errors]="form.get('field8').errors">
    </app-form-controls-select>

    <div *ngIf="isIndividual"
         class="group">
      <div *ngFor="let item of individualFields">
        <app-form-controls-select
          [label]="model.data[item].title"
          [options]="model.data[item].options"
          placeholder="Выберите значение"
          [formControlName]="item"
          [hideErrors]="true"
          [revertPositionDropdown]="revertPositionDropdown"
          [errors]="form.get(item).errors">
        </app-form-controls-select>
      </div>
    </div>

    <div *ngIf="isLawyer"
         class="group">
      <div *ngFor="let item of lawyerFields">
        <app-form-controls-select
          [label]="model.data[item].title"
          [options]="model.data[item].options"
          placeholder="Выберите значение"
          [formControlName]="item"
          [hideErrors]="true"
          [revertPositionDropdown]="revertPositionDropdown"
          [errors]="form.get(item).errors">
        </app-form-controls-select>
      </div>
    </div>

    <app-form-controls-select
      [label]="model.data['field12'].title"
      [options]="model.data['field12'].options"
      placeholder="Выберите значение"
      formControlName="field12"
      [hideErrors]="true"
      (change)="onChangeSelect('field12',$event)"
      [revertPositionDropdown]="revertPositionDropdown"
      [errors]="form.get('field12').errors">
    </app-form-controls-select>

    <app-form-controls-input
      [label]="model.data['field13'].title"
      placeholder="Введите значение"
      formControlName="field13"
      [hideErrors]="true"
      [errors]="form.get('field13').errors">
    </app-form-controls-input>

    <app-form-controls-select
      [label]="model.data['field15'].title"
      [options]="model.data['field15'].options"
      placeholder="Выберите значение"
      formControlName="field15"
      [hideErrors]="true"
      (change)="onChangeSelect('field15',$event)"
      [revertPositionDropdown]="revertPositionDropdown"
      [errors]="form.get('field15').errors">
    </app-form-controls-select>

    <app-form-controls-select
      class="form-control-field14"
      [label]="model.data['field14'].title"
      [options]="model.data['field14'].options"
      placeholder="Выберите значение"
      formControlName="field14"
      [hideErrors]="true"
      (change)="onChangeSelect('field14',$event)"
      [revertPositionDropdown]="revertPositionDropdown"
      [errors]="form.get('field14').errors">
    </app-form-controls-select>

    <div *ngIf="conservative"
      class="hint">
      <div class="title">Доступные инструменты</div>
      <p>Доля консервативных инструментов не должна быть ниже 80% от активов.</p>
      <p>Денежные средства на счетах и во вкладах в кредитных организациях с рейтингом не ниже суверенного, сниженного
        на 3 ступени.</p>
      <p>Облигации/еврооблигации либо эмитенты облигаций, имеющие рейтинг не ниже суверенного, сниженного на три ступени
        и сроком до погашения (оферты) до 2 лет. </p>
      <p>Инвестиционные паи открытых паевых инвестиционных фондов.</p>
      <p>Облигации/еврооблигации либо эмитенты облигаций, имеющие рейтинг не ниже суверенного, сниженного на три ступени
        и сроком до погашения (оферты) свыше 2 лет.</p>
      <p>Акции российских эмитентов, входящих в первый уровень котировального списка Московской биржи.</p>
      <p>Иные облигации, допущенные к организованным торгам.</p>

      <ul>
        <li>Ожидаемая доходность (%)<span>до 10 %</span></li>
        <li>Допустимый риск (%)<span>15 %</span></li>
        <li>Инвестиционный горизонт<span>12 месяцев</span></li>
      </ul>

    </div>
    <div *ngIf="balanced"
         class="hint">
      <div class="title">Доступные инструменты</div>
      <p>Доля консервативных инструментов не должна быть ниже 50%.от активов.</p>
      <p>Иные облигации</p>
      <p>Депозитарные расписки.</p>
      <p>Инвестиционные паи интервальных паевых инвестиционных фондов.</p>
      <p>Акции российских эмитентов, за исключением акций, включенных в первый уровень котировального списка Московской
        Биржи.</p>
      <p>Инвестиционные паи закрытых паевых инвестиционных фондов.</p>

      <ul>
        <li>Ожидаемая доходность (%)<span>от 10 до 15 %</span></li>
        <li>Допустимый риск (%)<span>20 %</span></li>
        <li>Инвестиционный горизонт<span>12 месяцев</span></li>
      </ul>

    </div>
    <div *ngIf="aggressive"
         class="hint">
      <div class="title">Доступные инструменты</div>
      <p>Доля консервативных инструментов не установлена.</p>
      <p>Инвестиционные паи закрытых паевых инвестиционных фондов.</p>
      <p>Иные акции.</p>
      <p>Ипотечные сертификаты участия.</p>
      <p>Производные финансовые инструменты.</p>

      <ul>
        <li>Ожидаемая доходность (%)<span>от 15 до 20 %</span></li>
        <li>Допустимый риск (%)<span>25 %</span></li>
        <li>Инвестиционный горизонт<span>12 месяцев</span></li>
      </ul>
    </div>

  </form>
</div>
