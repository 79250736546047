import {DataAction, DataActionTypes} from '@root/state/data/data.action';

export interface DataState{
  personal: any,
  private: any;

  valid: boolean;
  form: any
}

export const initialDataState: DataState = {
  personal: null,
  private: null,
  valid: null,
  form: null
}

export function dataReducer(state: DataState = initialDataState, action: DataAction): DataState {
  switch (action.type) {
    case DataActionTypes.Validation: {
      return {
        ...state,
        valid: action.payload
      }
    }
    case DataActionTypes.ValidationSuccess:{
      return {
        ...state,
        valid: true
      }
    }
    case DataActionTypes.PersonalNew:{
      return {
        ...state,
        personal: action.payload
      }
    }
    case DataActionTypes.PersonalLoad:
    case DataActionTypes.PersonalSuccess: {
      return {
        ...state,
        personal: action.payload
      }
    }
    case DataActionTypes.PrivateLoad:
    case DataActionTypes.PrivateSuccess:{
      return {
        ...state,
        private: action.payload
      };
    }
    default:{
      return state;
    }
  }
}

export const getAllData = (state: DataState) => state
export const getPersonalData = (state: DataState) => state.personal
export const getPrivateData = (state: DataState) => state.private

export const getValidation = (state: DataState) => {
  return state.valid
}
export const getValidationSuccessful = (state: DataState) => {
  return state.form
}

