import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotificationListComponent} from './notification-list/notification-list.component';
import {NotificationDetailComponent} from './notification-detail/notification-detail.component';

const routes: Routes = [
  { path: 'list', component: NotificationListComponent },
  { path: 'message/:id', component: NotificationDetailComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class NotificationRoutingModule { }
