<div class="personal-extension" *ngIf="form">
  <form [formGroup]="form">
    <div *ngFor="let item of data">
      <app-form-controls-input
        [label]="item.value"
        [disabled]="true"
        [formControlName]="item.label"></app-form-controls-input>
    </div>
  </form>
</div>
