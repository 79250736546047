import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {iSession, Session} from '../models';
import {StorageService} from '../../shared/modules/storage/services';
import {User} from '../../shared/models/user.model';
import {map} from 'rxjs/operators';
import {iResponse} from '@shared/models/response.interface';
import * as moment from 'moment';

@Injectable()
export class AuthService {
  private _session = new BehaviorSubject<any>(null); // current session of User
  private sessionObject: iSession = null;

  constructor(
    public http: HttpClient,
    public storageService: StorageService,
    public router: Router
  ) {
  }

  get session$(): Observable<iSession> {
    return this._session.asObservable();
  }

  set session(value: any) {
    this.sessionObject = <any> value;

    if (value == null) {
      this.storageService
        .removeToken()
        .removeAuth();
    } else {
      this.storageService.setAuth(JSON.stringify(value));
    }
    this._session.next(value);
  }

  signIn(model: any): Observable<any> {
    return this.http.post('login', model)
      .pipe(
        map((item: iResponse) => item.data)
      );
  }

  logOut() {
    this.http.get('logout')
      .subscribe(item => {
      });
  }

  signUp(model: any): Observable<any> {
    return this.http.post('registration', model);
  }

  remindPassword(model: any): Observable<any> {
    return this.http.post('restore', model);
  }

  getData(): Observable<any> {
    return this.http.get('user/getData').pipe(
      map((item: iResponse) => item.data)
    );
  }

  getUser(): User {
    if (this.sessionObject) {
      return this.sessionObject.user;
    }

    let auth = this.storageService.getAuth();
    let data = JSON.parse(auth || '{}');

    return new User(data);
  }

  // getDefaultUser() {
  //   if (this.sessionObject) {
  //     return this.sessionObject.user;
  //   }
  //
  //   const session = this.getSessionByStore();
  //   if (!session.token) {
  //     session.setUser(new User({
  //       role: RoleEnum.Guest
  //     }));
  //
  //     this.session = session;
  //   } else {
  //     this.session = this.getSessionByStore();
  //   }
  //   return session.user;
  // }

  // getSessionByStore(): Session {
  //   const auth = this.storageService.getAuth();
  //   const token = this.storageService.getToken();
  //   const data: any = JSON.parse(auth || '{}');
  //
  //   const session: Session = new Session()
  //     .setToken(token)
  //     .setUser(new User(data));
  //
  //   return session;
  // }

  makeBearToken(token: string) {
    return `Bearer ${token}`;
  }
}
