import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Session } from '@root/auth/models';
import { StorageService } from '@shared/modules/storage/services';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _session$ = new ReplaySubject<any>(); // current session of User
  private _session: Session = null;

  constructor(
    public storageService: StorageService,
  ) {
    this.updateSessionFromStore();
  }

  updateSessionFromStore() {
    let auth = JSON.parse(this.storageService.getAuth() || '{}');
    auth.token = auth.token || this.storageService.getToken();

    this._session = new Session(auth);
  }

  set session(session: Session) {
    if (session == null) {
      this._session.token = null;
      this.storageService
        .removeToken()
        .removeAuth();
    } else {
      this._session = session;
      this.storageService.setAuth(JSON.stringify(session.value));
      if (session.value?.token) {
        this.storageService.setToken(session.value.token);
      }
    }
    this._session$.next(session);
  }

  removeTokenFromSession() {
    if (!this._session) {
      return;
    }
    this._session._user = new User();
    this._session$.complete();
    this._session.token = null;
    this.storageService.setAuth(JSON.stringify(this._session));
  }

  get session(): Session {
    return this._session;
  }

  get session$(): Observable<Session> {
    return this._session$.asObservable();
  }

  get getSessionAsSubject(){
    return this._session$;
  }

  checkToken() {
    let value = this.storageService.getToken() ? true : false;
    console.log('check Token', value);
    return value;
  }
}
