import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {AuthGuard} from './shared/guards/guard';
import {AuthService} from './auth/services/auth.service';
import {StorageService} from './shared/modules/storage/services';
import {NavigationMenuService} from '@shared/modules/navigation-menu/navigation-menu.service';
import {NotificationService} from './shared/modules/common-notification/notification.service';
import {NotificationModule} from './notification/notification.module';
import {CommonNotificationModule} from './shared/modules/common-notification/common-notification.module';
import {StateModule} from '@root/state/state.module';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import {CommonTranslateModule} from '@shared/modules/common-translate/common-translate.module';
import {PlatformModule} from '@root/platforms/platform.module';
import {SignUpGuard} from '@shared/guards/signUpGuard';
import { PortalDisabledComponent } from './auth/portal-disabled/portal-disabled.component';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    PortalDisabledComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    StateModule,
    CommonTranslateModule,
    CommonNotificationModule,
    PlatformModule,
    NotificationModule
  ],
  providers: [
    AuthGuard,
    SignUpGuard,
    AuthService,
    NavigationMenuService,
    NotificationService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
