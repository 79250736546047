import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Configuration} from '@shared/modules/outer-config/models/config';

@Injectable({
  providedIn: 'root'
})
export class CommonTranslateService {

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs([Configuration.translate.default]);
    this.translateService.setDefaultLang(Configuration.translate.default);
  }
}
