
export class SignIn {
  public email: string;
  public password: string;

  get value() {
    return {
      email: this.email,
      password: this.password,
    };
  }

  constructor() {
  }

  isValid() {

    return true;
  }
}
