import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/modules/common-notification/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationStatus} from '../../shared/modules/common-notification/notification-status.enum';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit {
  notification: Notification;

  constructor(
      public notificationService: NotificationService,
      public router: Router,
      public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      if (data.params && data.params.id) {
        //this.getDetail(data.params.id);
      }
    });
  }
  //
  // getDetail(id: number) {
  //   this.notificationService.getByID(id).subscribe((item)=>{
  //     this.common-notification = new Notification(item.id).setValue(item);
  //     if (this.common-notification.read == NotificationStatus.New) {
  //       this.setReadMessage(id);
  //     }
  //   })
  // }
  //
  // remove(){
  //   this.notificationService.delete(this.common-notification.id).subscribe((item)=>{
  //     this.router.navigate(['/common-notification/list']);
  //   })
  // }
  //
  // back(){
  //   this.router.navigate(['/common-notification/list']);
  // }
  //
  // setReadMessage(id: number){
  //   this.notificationService.setRead(id).subscribe((item)=>{
  //     this.common-notification.setRead();
  //   })
  // }
}
