import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonFormService {
  private _submitted: boolean;

  _validate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _validate: boolean = false;

  constructor() { }

  get validate$(): Observable<boolean> {
    return this._validate$.asObservable();
  }

  set validate(value: boolean) {
    this._validate = value;
    this._validate$.next(value);
  }

  get submitted(): boolean {
    return this._submitted;
  }

  set submitted(value: boolean) {
    this._submitted = value;
  }

  get presSubmitted(): boolean {
    return this._submitted && this._validate
  }

}
