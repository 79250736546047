import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ValidationError, ValidationSuccess} from '@root/state/data/data.action';
import {CommonFormService} from '@shared/components/common-form/common-form.service';

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  providers: [CommonFormService],
  styleUrls: ['./common-form.component.scss']
})
export class CommonFormComponent implements OnInit, OnChanges {
  submitted = false;
  form: FormGroup;

  @Input()
  model: any; // model of data

  @Input()
  validate: boolean = false;

  @Output()
  validatedSuccessful: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('innerFormComponent') innerFormComponents;

  constructor(
    public fb: FormBuilder,
    public formService?: CommonFormService
  ) { }

  ngOnInit(): void {

  }

  buildForm(properties): FormGroup {
    this.form = this.fb.group(properties);

    return this.form;
  }

  validation(): boolean {
    if (!this.form) {
      throw Error('Inner component not have form');
    }

    this.form.markAllAsTouched();

    return this.form.valid;
  }

  submitForm() {
    this.formService.submitted = true;
    this.formService.validate = this.form.valid;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.submitOk();
  }

  submitOk() {
    throw new DOMError()
  }
  //Deprecated
  submit() {
    throw new DOMError()
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
