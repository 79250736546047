import { EventEmitter, Injectable } from '@angular/core';
import { iPrimeNgPaginator, Pagination } from '@shared/modules/paginator/pagination.model';
import { Configuration } from '@shared/modules/outer-config/models/config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    private _pagination: Pagination;

    private _changePage$: EventEmitter<Pagination> = new EventEmitter<any>();

    constructor() {
        this._pagination = new Pagination({
            pageNumber: 1,
            pageSize: Configuration.pagination.rowsPerPageOptions[0],
            totalItems: 100,
            totalPages: 255
        });
    }

    get changePage$(): Observable<Pagination> {
        return this._changePage$;
    }

    get pagination(): Pagination {
        return this._pagination;
    }

    set pagination(value: Pagination) {
        this._pagination = value;
    }

    changeStep($event: iPrimeNgPaginator) {
        this._pagination.pageSize = $event.rows;
        this._pagination.pageNumber = $event.page + 1;

        this._changePage$.next(this._pagination.value);
    }
}
