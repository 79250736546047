import {Injectable} from '@angular/core';
import {NavigationMenuType} from '@shared/modules/navigation-menu/navigation-menu-type.enum';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MenuItem} from 'primeng';
import {NavigationMenuItem} from '@shared/modules/navigation-menu/navigation-menu-item.interface';
import {map, take, tap} from 'rxjs/operators';
import {NavigationType} from '@shared/modules/navigation-menu/navigation.type';

@Injectable()
export class NavigationMenuService {
  isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _menu$: ReplaySubject<NavigationMenuItem[]> = new ReplaySubject<NavigationMenuItem[]>();
  private isOpen: boolean = false;
  private menuReady: NavigationMenuItem[];

  constructor(public http: HttpClient) {
  }

  get menu$(): Observable<NavigationMenuItem[]> {
    return this._menu$.asObservable();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.isOpen$.next(this.isOpen);
    console.log('is Menu', this.isOpen);
  }

  // getMenu(): Observable<any> {
  //   return this.http.get('/menu/getUseQuestionnaireMainList');
  // }

  private prepareMenu(menu: NavigationMenuItem[]): NavigationMenuItem[] {
    (menu || []).map((item) => {
      item.shortTitle = `NavigationMenu_${item.name}`;

      if (NavigationType[item.name]) {
        NavigationType[item.name](item);
      }
    });
    this.menuReady = menu;
    return menu;
  }

  getMenu(): Observable<NavigationMenuItem[]> {
    return this.http.get<MenuItem[]>('menu/getMainList', {}).pipe(
      take(1),
      map(item => this.prepareMenu(item['data'])),
      tap(item => this._menu$.next(item))
    );
  }

  getCurrentMenu$(type: NavigationMenuType): Observable<NavigationMenuItem> {
    return this.menu$.pipe(
      map(menuItem => menuItem.filter(item => item.name === type)[0])
    );
  }



}
