import {NotificationActionType, NotificationAction} from './notification.action';

export interface NotificationState{
  error: string;
  info: string;
  success: string;
  warning: string;
}

export const initialNotificationState: NotificationState = {
  error: '',
  info: '',
  success: '',
  warning: ''
}

export function notificationReducer(state: NotificationState = initialNotificationState, action: NotificationAction): NotificationState {
  switch (action.type) {
    case NotificationActionType.Error: {
      return {
        ...state,
        error: action.payload
      }
    }
    case NotificationActionType.Info: {
      return {
        ...state,
        info: action.payload
      }
    }
    case NotificationActionType.Success: {
      return {
        ...state,
        success: action.payload
      }
    }
    case NotificationActionType.Warning: {
      return {
        ...state,
        warning: action.payload
      }
    }
    default:{
      return state;
    }
  }
}
