<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="wievs_tuggle">
        <div class="row">
          <a (click)="onCalendar()">
            <i class="pi pi-calendar"></i>
          </a>

          <a (click)="onList()">
            <i class="pi pi-list"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
