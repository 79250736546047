import {Injectable, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modal: NgbModal) {
  }

  open<T>(template: TemplateRef<T>): Observable<T> {
    if (!template) return of<T>();

    const modalRef = this.modal.open(template, {centered: true});

    return from(modalRef.result);
  }

}
