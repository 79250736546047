import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BootstrapModule} from './bootstrap/bootstrap.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FileUploaderComponent} from './modules/file-uploader/file-uploader.component';
import {PipesModule} from './modules/pipes/pipes.module';
import {PrimeModule} from './modules/prime/prime.module';
import {NotificationModule} from '../notification/notification.module';
import {PaginatorModule} from './modules/paginator/paginator.module';
import {OuterConfigModule} from './modules/outer-config/outer-config.module';
import {FormControlsModule} from './modules/form-controls/form-controls.module';
import {CommonFormComponent} from './components/common-form/common-form.component';
import {StepsComponent} from '@shared/components/steps/steps.component';
import {PersonalDataComponent} from '@shared/components/personal-data/personal-data.component';
import {PrivateDataComponent} from '@shared/components/private-data/private-data.component';
import {CommonFormService} from '@shared/components/common-form/common-form.service';
import {CommonTranslateModule} from '@shared/modules/common-translate/common-translate.module';
import {HttpInterceptorProviders} from '@shared/interceptors';
import {NavigationMenuModule} from '@shared/modules/navigation-menu/navigation-menu.module';
import {SessionService} from '@shared/services/session.service';
import { MouseDirective } from './directives/mouse.directive';
import {MouseService} from '@shared/services/mouse.service';
import { SwitchViewComponent } from './components/switch-view/switch-view.component';
import {ModalModule} from '@shared/modules/modal/modal.module';

@NgModule({
  declarations: [
    FileUploaderComponent,
    CommonFormComponent,
    StepsComponent,
    PersonalDataComponent,
    PrivateDataComponent,
    MouseDirective,
    SwitchViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonTranslateModule,
    BootstrapModule,
    PaginatorModule,
    OuterConfigModule,
    ReactiveFormsModule,
    PipesModule,
    NavigationMenuModule,
    FormControlsModule,
    PrimeModule,
    ModalModule,
    NotificationModule,
  ],
  providers: [
    CommonFormService,
    SessionService,
    HttpInterceptorProviders
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    BootstrapModule,
    PaginatorModule,
    OuterConfigModule,
    PipesModule,
    ReactiveFormsModule,
    FormControlsModule,
    PrimeModule,
    NavigationMenuModule,
    CommonTranslateModule,
    FileUploaderComponent,
    StepsComponent,
    NotificationModule,
    MouseDirective,
    PersonalDataComponent,
    PrivateDataComponent,
    SwitchViewComponent
  ],
  entryComponents: []
})
export class SharedModule {
}
