import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalService} from '@shared/modules/modal/modal.service';
import { ModalComponent } from './modal/modal.component';


@NgModule({
  declarations: [ModalComponent],
  imports: [
    CommonModule,
    NgbModalModule
  ],
  exports: [
    ModalComponent
  ],
  providers: [
    ModalService
  ]
})
export class ModalModule { }
