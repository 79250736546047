import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonFormComponent} from '@shared/components/common-form/common-form.component';
import {FormBuilder, Validators} from '@angular/forms';
import {State} from '@root/state/state.interface';
import {Store} from '@ngrx/store';
import {DataPersonalNew} from '@root/state/data/data.action';
import {PersonalData} from '@shared/components/personal-data/personal-data.model';
import {CommonFormService} from '@shared/components/common-form/common-form.service';
import {CustomValidationService} from '@shared/modules/form-controls/custom-validation.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalDataComponent extends CommonFormComponent implements OnInit {
  @Input()
  model: PersonalData;

  @Input()
  isValidatePassword = true;

  @Input()
  disable: boolean = false
  
  @Input()
  typeComponent: boolean = true; // true - inner; false - outer

  constructor(
    public fb: FormBuilder,
    public formService: CommonFormService,
    public store: Store<State>
  ) {
    super(fb, formService);
  }

  getRuleOfValidationFieldForm(field: string) {
    let value = [];

    switch (field) {
      case 'password': {
        if (this.isValidatePassword) {
          value = [Validators.required];
        }
        break;
      }
    }

    return value;
  }

  ngOnInit(): void {
    this.buildForm({
      fio: [this.model.fio, Validators.required],
      email: [this.model.email, [Validators.required, Validators.email]],
      pass: this.fb.group({
        password: [this.model.password, this.getRuleOfValidationFieldForm('password')],
        confirmPassword: [this.model.password, this.getRuleOfValidationFieldForm('password')],
      }, {
        validators: CustomValidationService.confirmPassword,
      })
    });

    this.form.valueChanges.subscribe((item) => {
      this.model.fio = item.fio;
      this.model.email = item.email;
      this.model.password = item.pass.password;
      this.store.dispatch(new DataPersonalNew(this.model.value));
    });
  }

  validation() {
    if (!this.form) {
      return false;
    }

    this.form.markAllAsTouched();

    return this.form.valid;
  }
}
