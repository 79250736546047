import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType,} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
// app
import * as menuActions from './navigation-menu.actions';
import {State} from '../state.interface';
import {NavigationMenuService} from '@shared/modules/navigation-menu/navigation-menu.service';

@Injectable()
export class NavigationMenuEffect {
  constructor(private actions$: Actions,
              private store$: Store<State>,
              private navigationMenuService: NavigationMenuService) {
  }

  // getMenu$ = createEffect(() => this.actions$.pipe(
  //   ofType(menuActions.loadNavigationMenu),
  //   mergeMap((action) =>
  //     this.navigationMenuService.menu
  //       .pipe(
  //         map(item => {
  //           return {type: '[LoadSuccess] LoadSuccess Menu', payload: item};
  //         }),
  //         catchError(() => EMPTY)
  //       ))
  // ));
}
