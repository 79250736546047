import {Action} from '@ngrx/store';
import {User} from '@shared/models/user.model';

export enum DataActionTypes {
  PersonalNew = '[PersonalNew] data',
  PersonalLoad = '[PersonalLoad] data',
  PersonalSuccess = '[PersonalSuccess] data',
  PersonalUpdate = '[PersonalUpdate] data',
  PrivateLoad = '[PrivateLoad] data',
  PrivateSuccess = '[PrivateSuccess] data',
  PrivateUpdate = '[PrivateUpdate] data',
  Validation = '[Validation] data',
  ValidationSuccess = '[ValidationSuccess] data',
  ValidationError = '[ValidationError] data',
}

export class Validation implements Action {
  readonly type = DataActionTypes.Validation;

  constructor(public payload: boolean) {
  }
}

export class ValidationError implements Action {
  readonly type = DataActionTypes.ValidationError;

  constructor() {
  }
}

export class ValidationSuccess implements Action {
  readonly type = DataActionTypes.ValidationSuccess;

  constructor(public payload: any) {
  }
}

export class DataPersonalNew implements Action {
  readonly type = DataActionTypes.PersonalNew;

  constructor(public payload: any) {
  }
}

export class DataPersonalLoad implements Action {
  readonly type = DataActionTypes.PersonalLoad;

  constructor(public payload: any) {
  }
}

export class DataPersonalSuccess implements Action {
  readonly type = DataActionTypes.PersonalSuccess;

  constructor(public payload: User) {
  }
}

export class DataPersonalUpdate implements Action {
  readonly type = DataActionTypes.PersonalUpdate;

  constructor(public payload: User) {
  }
}

export class DataPrivateLoad implements Action {
  readonly type = DataActionTypes.PrivateLoad;

  constructor(public payload: User) {
  }
}

export class DataPrivateSuccess implements Action {
  readonly type = DataActionTypes.PrivateSuccess;

  constructor(public payload: User) {
  }
}

export class DataPrivateUpdate implements Action {
  readonly type = DataActionTypes.PrivateUpdate;

  constructor(public payload: User) {
  }
}

export type DataAction =
  DataPersonalNew
  | DataPersonalLoad
  | DataPersonalSuccess
  | DataPersonalUpdate
  | DataPrivateLoad
  | DataPrivateSuccess
  | DataPrivateUpdate
  | Validation
  | ValidationSuccess
  | ValidationError;
