import { Injector, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {Configuration} from '../../outer-config/models/config';

@Pipe({
  name: 'customDateTime'
})
export class DateTimePipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, format?: string): any {
    format = format || Configuration.format['date-time'];
    return moment(value).format(format);
  }

}
