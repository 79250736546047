import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator.component';
import {PaginationService} from '@shared/modules/paginator/pagination.service';

@NgModule({
  declarations: [
    PaginatorComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    PaginationService
  ],
  exports: [
    PaginatorComponent
  ]
})
export class PaginatorModule { }
