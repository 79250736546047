import {Platform} from '@root/platforms/platform.model';
import {PlatformTypeEnum} from '@root/platforms/platform-type.enum';
import {PlatformBitkogan} from '@root/platforms/bitkogan/platform-bitkogan';
import {iPlatform} from '@root/settings/interfaces';

export const PlatformType = {
  bitKogan: (item): iPlatform => {
    return {
      role: ''
    }
  },
  'dev.up.teamcraft.ru': (item): iPlatform => {
    return {
      role: ''
    }
  },
  localhost: (item): iPlatform => {
    return {
      role: ''
    }
  },
  default: (item): iPlatform => {
    return {
      role: ''
    }
  }
};
