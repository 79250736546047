import {Directive, HostListener, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NavigationMenuService} from '@shared/modules/navigation-menu/navigation-menu.service';

@Directive({
  selector: '[app-tablet-navigation-item-click]'
})
export class TabletNavigationItemClickDirective {

  get isAdaptive() {
    return this.document.body.clientWidth < 1024;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public leftSideMenuService: NavigationMenuService
  ) {
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    if (this.isAdaptive) {
      this.leftSideMenuService.toggle();
    }
  }

}
