import {NgModule} from '@angular/core';
import {NotificationListComponent} from './notification-list/notification-list.component';
import {NotificationRoutingModule} from './notification-routing.module';
import {NotificationDetailComponent} from './notification-detail/notification-detail.component';
import {CommonNotificationModule} from '../shared/modules/common-notification/common-notification.module';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationDetailComponent
  ],
  imports: [
    CommonNotificationModule,
    NotificationRoutingModule
  ]
})
export class NotificationModule {
}
