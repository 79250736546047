<a class="nav-link"
   [routerLink]="'/common-notification/list'">
  <i class="fa fa-bell"></i>
  <span class="badge badge-warning navbar-badge"
        *ngIf="count"
        [innerText]="count"></span>
</a>

<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
     *ngIf="false"
     [ngClass]="{ 'show': showNotification}">
  <span class="dropdown-item dropdown-header">15 Notifications</span>
  <div class="dropdown-divider"></div>
  <a href="#" class="dropdown-item">
    <i class="fas fa-envelope mr-2"></i> 4 new messages
    <span class="float-right text-muted text-sm">3 mins</span>
  </a>
  <div class="dropdown-divider"></div>
  <a href="#" class="dropdown-item">
    <i class="fas fa-users mr-2"></i> 8 friend requests
    <span class="float-right text-muted text-sm">12 hours</span>
  </a>
  <div class="dropdown-divider"></div>
  <a href="#" class="dropdown-item">
    <i class="fas fa-file mr-2"></i> 3 new reports
    <span class="float-right text-muted text-sm">2 days</span>
  </a>
  <div class="dropdown-divider"></div>
  <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
</div>
