import { Injector, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {Configuration} from '../../outer-config/models/config';

@Pipe({
  name: 'customDate'
})
export class DatePipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, format?: string): any {
    format = format || Configuration.format.date;
    return moment(value).format(format);
  }

}
