import {Action} from '@ngrx/store';

export enum NotificationActionType {
  Error = '[Error]',
  Info = '[Info]',
  Success = '[Success]',
  Warning = '[Warning]',
}

export class Error implements Action {
  readonly type = NotificationActionType.Error;

  constructor(public payload: string) {
  }
}
export class Info implements Action {
  readonly type = NotificationActionType.Info;

  constructor(public payload: string) {
  }
}
export class Success implements Action {
  readonly type = NotificationActionType.Success;

  constructor(public payload: string) {
  }
}
export class Warning implements Action {
  readonly type = NotificationActionType.Warning;

  constructor(public payload: string) {
  }
}

export type NotificationAction = Error | Info | Success | Warning;
