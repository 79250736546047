import {Injectable} from '@angular/core';
import {Actions, Effect, ofType,} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {map, mergeMap} from 'rxjs/operators';
// app
import * as authActions from './authentication.action';
import {State} from '../state.interface';
import {AuthService} from '@root/auth/services/auth.service';
import {StorageService} from '@shared/modules/storage/services';
import {AuthenticationSignInUser, LogOut} from './authentication.action';
import {Session} from '@root/auth/models';
import {User} from '@shared/models/user.model';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationEffect {
    constructor(private actions$: Actions,
                private store$: Store<State>,
                private router: Router,
                private storageService: StorageService,
                private authService: AuthService) {
    }

    @Effect()
    logOut$ = this.actions$
      .pipe(
        ofType(authActions.AuthActionsTypes.LogOut),
        map((action: LogOut) => {
          console.log('effect logOut$');
          this.storageService.removeAuth();
          //this.authService.setSession(new Session().setUser(new User()));

          return new authActions.DefaultUser()
        }))

    @Effect()
    authenticationSignIn$ = this.actions$
        .pipe(
            ofType(authActions.AuthActionsTypes.SignInUser),
            map((action: AuthenticationSignInUser) => {
              return new authActions.AuthenticationSignInUserError('error token');
                // return this.authService.signIn(action['payload'])
                //   .subscribe((item: any) => {
                //     let data = { ...action.payload};
                //     data['token'] = this.authService.makeBearToken(item.token);
                //
                //     this.storageService
                //       .setToken(data['token'])
                //       .setAuth(JSON.stringify(data));
                //     return new authActions.AuthenticationSignInUserSuccess(data)
                //   },
                //     (error)=>{
                //       console.log('signIn error', error);
                //       return new authActions.AuthenticationSignInUserSuccess('token');
                //     }
                //   )
            }))
}
