export interface iPagination {
    itemsPerPage: any[];
    pageInfo: iPageInfo;
}

export class iPageInfo {
    pageNumber: number;
    pageSize: number;
    totalItems?: number;
    totalPages?: number;
}

export interface iPrimeNgPaginator {
    page: number;
    first: number;
    rows: number;
    pageCount: number;
}

export class Pagination {
    pageNumber: number;
    pageSize: number;
    totalItems?: number;
    totalPages?: number;

    constructor(pageInfo: iPageInfo) {
        if (!pageInfo) {
            return;
        }

        this.pageNumber = pageInfo.pageNumber;
        this.pageSize = pageInfo.pageSize;
        this.totalItems = pageInfo.totalItems;
        this.totalPages = pageInfo.totalPages;
    }

    set value(data: any) {
        this.pageNumber = data.pageNumber;
        this.pageSize = data.pageSize;
        this.totalItems = data.totalItems;
        this.totalPages = data.totalPages;
    }

    get value() {
        return {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            totalItems: this.totalItems,
            totalPages: this.totalPages
        }
    }
}
