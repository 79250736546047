import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BitkoganModule} from '@root/platforms/bitkogan/bitkogan.module';
import {PlatformService} from '@root/platforms/platform.service';

@NgModule({
  declarations: [],
  providers: [
    PlatformService
  ],
  imports: [
    BitkoganModule,
    CommonModule
  ]
})
export class PlatformModule {
}
