<div class="modal-header">
  <h2>{{ title }}</h2>
  <button type="button" class="close" style="outline: none;" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ng-content></ng-content>
</div>

<div class="modal-footer">
  <div class="">
    <button
      *ngIf="confirmShown()"
      (click)="confirmClick()"
      class="btn btn-primary btn-add"
    >
      {{ confirmText }}
    </button>

    <button *ngIf="cancelShown()" class="button-border_grey button_small">
      {{ cancelText }}
    </button>
  </div>
</div>
