import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NotificationMessageComponent} from './notification-message/notification-message.component';
import {NotificationPanelComponent} from './notification-panel/notification-panel.component';
import {CommonTranslateModule} from '@shared/modules/common-translate/common-translate.module';

@NgModule({
  declarations: [
    NotificationMessageComponent,
    NotificationPanelComponent
  ],
  imports: [
    CommonTranslateModule,
    CommonModule,
    RouterModule
  ],
  exports: [
    NotificationMessageComponent,
    NotificationPanelComponent
  ]
})
export class CommonNotificationModule {
}
