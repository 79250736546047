import { Injectable } from '@angular/core';
import {Observable , of} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RequestService {

  constructor(
      protected http: HttpClient
  ) {
  }

  post(url: string, model: any): Observable<any>{
    return this.http.post(url, model);
  }

  get(url: string): Observable<any>{
    return this.http.get(url);
  }

  put(url: string, model: any): Observable<any>{
    return this.http.put(url, model);
  }

    delete(url: string): Observable<any>{
    return this.http.delete(url);
  }


}
