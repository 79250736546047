import {Action} from '@ngrx/store';
import {NavigationMenuItem} from '@shared/modules/navigation-menu/navigation-menu-item.interface';

import { createAction, props } from '@ngrx/store';
import {State} from '@root/state/state.interface';

export const loadNavigationMenu = createAction('[Load] Load Menu');
export const loadSuccessNavigationMenu = createAction(
  '[LoadSuccess] LoadSuccess Menu',
  (response: any) => response.navigationMenu.navigationMenu );
export const updateNavigationMenu = createAction('[Update] Update Menu');
